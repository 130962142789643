import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  LinearProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getExcludedSamplesLot, getSamples } from "../services/drxna.service";
import TreeComponent from "../components/TreeComponent";
import CloseIcon from "@mui/icons-material/Close";
import HttpService from "../services/http.service";
import { drxnaUrl } from "../services/url.service";
import { useNavigate } from "react-router-dom";
import SearchInputComponent from "../components/SearchInputComponent";

const LotsPage = () => {
  const navigate = useNavigate();
  const [samplesExcludingDrxna, setSamplesExcludingDrxna] = useState([]);
  const [rawSamplesExcludingDrxna, setRawSamplesExcludingDrxna] = useState([]);
  const [lotSearchVal, setLotSearchVal] = useState("");

  const [selectedSamples, setSelectedSamples] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedSampleIds, setSelectedSampleIds] = useState([]);

  function generateShipmentName() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const sampleNames = selectedSampleIds
      .map((sample) => sample.sample_name)
      .join("/")
      .replace(/\s+/g, "_");

    const shipmentName = `SH-${day}-${month}-${year}-(${sampleNames})`;
    setShipmentName(shipmentName);
  }

  function filterString(input) {
    const items = input.split(",");
    const filteredItems = items.filter(
      (item) => item && !item.startsWith("lot")
    );

    const objectArray = filteredItems.map((sample_id, index) => ({
      sample_id: sample_id,
      sample_name: null,
      inspection_status: null,
    }));

    let final = objectArray;

    return final;
  }
  const getSelectedSampleIds = (val) => {
    setSelectedSampleIds(filterString(val));
  };

  const handleCheckboxChange = (event, sample) => {
    if (event.target.checked) {
      setSelectedSampleIds((prev) => [
        ...prev,
        {
          sample_id: sample?.["Sample Id"],
          sample_name: sample?.["Sample Name"] || null,
          inspection_status: sample?.inspection_status || null,
        },
      ]);
    } else {
      setSelectedSampleIds((prev) =>
        prev?.filter((item) => item?.sample_id !== sample?.["Sample Id"])
      );
    }
  };

  const getSamplesExcludingDrxna = () => {
    setLoading(true);
    let organizationID = localStorage.getItem("orgid");
    getExcludedSamplesLot(organizationID)
      .then((res) => {
        const allSamplesExcludingDrxna = res?.data?.lots?.filter(
          (alfw) => alfw?.type !== "reagent" && alfw?.n_sample > 0
        );
        setSamplesExcludingDrxna(allSamplesExcludingDrxna);
        setRawSamplesExcludingDrxna(allSamplesExcludingDrxna);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const [samplesList, setSamplesList] = useState([]);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getAllSamples = (p) => {
    setLoading(true);
    getSamples(p)
      .then((res) => {
        setSamplesList(res?.data?.samples || []);
        setPage(res?.data?.pagination?.total_pages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getSamplesExcludingDrxna();
  }, []);

  useEffect(() => {
    getAllSamples(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const commanSeparated =
      selectedSampleIds
        ?.filter((item) => !item?._id)
        ?.map((item) => item?.sample_id)
        .join(",") + ",";

    if (commanSeparated) {
      setSelectedSamples(commanSeparated);
    }
  }, [selectedSampleIds]);

  const removeSampleToShipment = (id) => {
    const filtered = selectedSampleIds.filter(
      (sample) => sample?.sample_id !== id
    );
    setSelectedSampleIds(filtered);
  };

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [shipmentName, setShipmentName] = useState("");

  const AddSampleToShipment = (shipmentId) => {
    const sampleIds = selectedSampleIds?.map((item) => item?.sample_id);
    setLoading(true);
    HttpService.put(
      `${drxnaUrl}shipment/${shipmentId}/sample/?ignore_failure=true`,
      { sample_id_list: sampleIds }
    )
      .then((res) => {
        if (res?.data?.data) {
          navigate(`/shipment/${shipmentId}`);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const creatShipment = () => {
    setLoading(true);
    HttpService.post(`${drxnaUrl}shipment/`, {
      name: shipmentName,
    })
      .then((res) => {
        if (res?.data?.data?._id) {
          AddSampleToShipment(res?.data?.data?._id);
        }
        setOpenCreateModal(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const searchFilterLogic = (value) => {
    if (value === "") {
      setSamplesExcludingDrxna(rawSamplesExcludingDrxna);
    }

    setLotSearchVal(value);

    const excludingDrxnaFiltered = rawSamplesExcludingDrxna.filter((item) =>
      item.name.trim().toLowerCase().startsWith(value.toLowerCase())
    );
    if (value !== "") {
      setSamplesExcludingDrxna(excludingDrxnaFiltered);
    }
  };

  const [listView, setListView] = useState(true);

  const getShipmentStatus = (status, shipment) => {
    let validId =
      shipment !== "" && shipment !== null && shipment !== undefined;
    let shipment_status;
    if (status === "created" && validId) {
      shipment_status = "In proccess";
    } else if (status === "ready" && validId) {
      shipment_status = "Completed";
    } else {
      shipment_status = "None";
    }

    return shipment_status;
  };

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Box sx={{ position: "fixed", top: "70px", width: "100%", zIndex: 999 }}>
        {loading && (
          <LinearProgress
            sx={{
              backgroundColor: "#f1f5f9",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#ff6412ad",
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          py: {
            xs: 2,
            md: 4,
          },
          px: {
            xs: 2,
            md: 5,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            mb: 3,
            height: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "16px", sm: "22px" },
              fontWeight: "600",
              color: "#1e293b",
              fontFamily: "Open Sans",
            }}
          >
            Samples Page
          </Typography>
          {selectedSampleIds?.length !== 0 && (
            <Button
              sx={{
                textTransform: "none",
                background: "#1e5267",
                ":hover": { background: "#1e5267" },
                fontFamily: "Open Sans",
                borderRadius: "8px",
                height: "45px",
                px: 3,
              }}
              onClick={() => {
                setOpenCreateModal(true);
                generateShipmentName();
              }}
              variant="contained"
            >
              Create Shipment
            </Button>
          )}
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              overflowX: "auto",
              width: "100%",
              mb: 1,
            }}
          >
            {selectedSampleIds?.map((sample, index) => (
              <Box
                variant="outlined"
                color={sample?.inspection_status ? "success" : "warning"}
                sx={{
                  fontFamily: "Open Sans",
                  pl: 2,
                  pr: 1,
                  display: "flex",
                  gap: 2,
                  height: "35px",
                  minWidth: "max-content",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "8px",
                  border: sample?.inspection_status
                    ? "1px solid #16a34a"
                    : "1px solid #eab308",
                  color: sample?.inspection_status ? "#16a34a" : "#eab308",
                }}
                key={index}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 4,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "12px", sm: "13px" },
                        fontFamily: "Open Sans",
                      }}
                    >
                      Name: {sample?.sample_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "12px", sm: "13px" },
                        fontFamily: "Open Sans",
                      }}
                    >
                      ID: {sample?.sample_id}
                    </Typography>
                  </Box>

                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSampleToShipment(sample?.sample_id);
                    }}
                    sx={{
                      fontSize: "16px",
                      width: { xs: "20px", sm: "25px" },
                      height: { xs: "20px", sm: "25px" },
                      ":hover": { color: "red" },
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              my: { xs: 2, sm: 3 },
              display: { xs: "none", sm: "flex" },
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontFamily: "Open Sans" }}>
              Choose samples below to include in your package.
            </Typography>

            <Box
              sx={{
                display: "flex",
                border: "1px solid #ff6412",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <Button
                onClick={() => {
                  setListView(true);
                }}
                sx={{
                  background: listView ? "#ff6412" : "white",
                  ":hover": {
                    background: listView ? "#ff6412" : "white",
                  },
                  color: listView ? "white" : "#ff6412",
                  textTransform: "none",
                  boxShadow: "none",
                  fontSize: { xs: "12px", sm: "13px" },
                  fontFamily: "Open Sans",
                  borderRadius: "0px",
                  height: "35px",
                }}
              >
                List view
              </Button>
              <Button
                onClick={() => {
                  setListView(false);
                }}
                sx={{
                  background: !listView ? "#ff6412" : "white",
                  ":hover": {
                    background: !listView ? "#ff6412" : "white",
                  },
                  color: !listView ? "white" : "#ff6412",
                  height: "35px",
                  textTransform: "none",
                  boxShadow: "none",
                  fontSize: { xs: "12px", sm: "13px" },
                  fontFamily: "Open Sans",
                  borderRadius: "0 8px 8px 0",
                }}
              >
                Lots view
              </Button>
            </Box>
          </Box>
          {listView ? (
            <Box>
              <TableContainer
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  mb: 2,
                }}
              >
                <Table>
                  <TableHead
                    sx={{
                      borderBottom: "1px solid #e2e8f0",
                      position: "sticky",
                      top: 0,
                      left: 0,
                      background: "#fff",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Sample ID
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          minWidth: "200px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Sample Name
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          minWidth: "max-content",
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Description
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Shipment
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Action
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {samplesList.length !== 0 &&
                      samplesList?.map((sample, index) => (
                        <TableRow
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/sample/${sample?.["Sample Id"]}`);
                          }}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? "#FFFFFF" : "#fff3eb",
                            ":hover": {
                              backgroundColor: "#e0f2fe",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              px: "16px",
                            }}
                          >
                            <Typography sx={{ fontFamily: "Open Sans" }}>
                              {sample?.["Sample Id"]}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 0,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography sx={{ fontFamily: "Open Sans" }}>
                                {sample?.["Sample Name"]}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 0,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  minWidth: "300px",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                {sample?.["Description"]}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 2.5,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography sx={{ fontFamily: "Open Sans" }}>
                                {sample?.["status"]}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 2.5,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  color:
                                    getShipmentStatus(
                                      sample?.status,
                                      sample?.shipment_id
                                    ) === "In proccess"
                                      ? "#0ea5e9"
                                      : getShipmentStatus(
                                            sample?.status,
                                            sample?.shipment_id
                                          ) === "Completed"
                                        ? "#22c55e"
                                        : "black",
                                  minWidth: "max-content",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                {getShipmentStatus(
                                  sample?.status,
                                  sample?.shipment_id
                                )}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                            {sample?.shipment_id ? (
                              <Button
                                size="sm"
                                variant="outlined"
                                sx={{
                                  textTransform: "none",
                                  fontSize: "12px",
                                  minWidth: "max-content",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(`/shipment/${sample?.shipment_id}`);
                                }}
                              >
                                View Package
                              </Button>
                            ) : getShipmentStatus(
                                sample?.status,
                                sample?.shipment_id
                              ) === "None" ? (
                              <Checkbox
                                sx={{
                                  color: "#1e5267",
                                  "&.Mui-checked": {
                                    color: "#1e5267",
                                  },
                                }}
                                value={sample?.["Sample Id"]}
                                checked={selectedSampleIds?.some(
                                  (item) =>
                                    item?.sample_id === sample?.["Sample Id"]
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  handleCheckboxChange(e, sample);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            ) : (
                              <Box></Box>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {page > 1 && (
                <Box
                  sx={{
                    pb: 2,
                  }}
                >
                  <Pagination
                    count={page}
                    page={currentPage}
                    onChange={(e, p) => {
                      setCurrentPage(p);
                    }}
                    variant="outlined"
                    shape="rounded"
                  />
                </Box>
              )}
            </Box>
          ) : (
            samplesExcludingDrxna && (
              <Box>
                <SearchInputComponent
                  hasClear={lotSearchVal}
                  clearAction={() => {
                    searchFilterLogic("");
                  }}
                  iconStart={false}
                  bgkcolor={"#fff"}
                  value={lotSearchVal}
                  handleChange={(e) => {
                    searchFilterLogic(e.target.value);
                  }}
                  placeholder={"Search by Lot name"}
                />
                <Box
                  sx={{
                    width: "100%",
                    maxHeight: "60vh",
                    minHeight: "60vh",
                    overflowY: "auto",
                    mb: 2,
                    border: "1px solid #ccc",
                    background: "#fff",
                  }}
                >
                  <Box>
                    <TreeComponent
                      childs={samplesExcludingDrxna}
                      setSaved={false}
                      getSelectedSampleIds={getSelectedSampleIds}
                      selectedSamples={selectedSamples}
                      setSelectedSamples={(val) => {
                        setSelectedSamples(val);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )
          )}
        </Box>
        <Dialog
          fullWidth={true}
          sx={{ height: 450 }}
          maxWidth={"xs"}
          open={openCreateModal}
          onClose={() => {
            setOpenCreateModal(false);
          }}
        >
          <DialogTitle>
            <Typography sx={{ fontFamily: "Open Sans" }}>
              Add Package Name
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Input
                value={shipmentName}
                sx={{ width: "100%" }}
                onChange={(e) => {
                  setShipmentName(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button
                onClick={creatShipment}
                sx={{
                  width: "100%",
                  textTransform: "none",
                  background: "#1e5267",
                  ":hover": { background: "#1e5267" },
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                }}
                variant="contained"
              >
                Create
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default LotsPage;
