import { Box, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SendIcon from "@mui/icons-material/Send";
// import BiotechIcon from "@mui/icons-material/Biotech";

const BottomNav = () => {
  const location = useLocation();
  const isOnShipments =
    location.pathname.includes("/shipment/") ||
    location.pathname.includes("/sample/");

  return (
    <Box
      sx={{
        width: "100%",
        display: { xs: "flex", md: "none" },
        bottom: 0,
        left: 0,
        height: "80px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "50%",
          height: "80px",
        }}
      >
        <NavLink
          to="/samples"
          className={({ isActive }) => {
            return isActive ? "activeNavLink" : "inActiveNavLink";
          }}
          style={{
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <FilterAltIcon sx={{ rotate: "180deg", fontSize: "25px" }} />

            <Typography sx={{ fontFamily: "Open Sans", fontSize: "16px" }}>
              Samples
            </Typography>
          </Box>
        </NavLink>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "50%",
          height: "80px",
        }}
      >
        <NavLink
          to="/shipments"
          className={({ isActive }) => {
            return isActive || isOnShipments
              ? "activeNavLink"
              : "inActiveNavLink";
          }}
          style={{
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <SendIcon sx={{ fontSize: "25px" }} />

            <Typography sx={{ fontFamily: "Open Sans", fontSize: "16px" }}>
              Shipments
            </Typography>
          </Box>
        </NavLink>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          width: "33.33%",
          height: "80px",
        }}
      >
        <NavLink
          to="/workflows"
          className={({ isActive }) => {
            return isActive ? "activeNavLink" : "inActiveNavLink";
          }}
          style={{
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <BiotechIcon sx={{ fontSize: "30px" }} />

            <Typography sx={{ fontFamily: "Sfregular", fontSize: "16px" }}>
              Workflows
            </Typography>
          </Box>
        </NavLink>
      </Box> */}
    </Box>
  );
};

export default BottomNav;
