import React, { useState } from "react";

import { Box, Typography } from "@mui/material";
import { TreeView, TreeItem } from "@mui/x-tree-view";

import { getSamplesByLot } from "../services/drxna.service";

const WrapperTree = (props) => {
  const [expanded, setExpanded] = useState([]);
  const [samples, setSamples] = useState(null);
  const prevlotsstr = props?.rtlot + "lot" + props?.id + ",";

  let organizationID = localStorage.getItem("orgid");

  const handleSelection = (id, slt, sName) => {
    const sls = samples.map((ls) => ls["Sample Id"]);
    props.handleSelectSample(id, slt, sls, prevlotsstr, sName);
  };

  const handleChange = (event, nodes) => {
    setExpanded(nodes);

    if (nodes) {
      getSamplesByLot(nodes, organizationID)
        .then((sres) => {
          if (props?.showFiltered) {
            const allSamples = sres?.data?.samples?.filter(
              (sample) => sample.status !== "ready"
            );
            // console.log(sres?.data?.samples, "sas");
            setSamples(allSamples || []);
          } else {
            setSamples(sres?.data?.samples);
          }
        })
        .catch((e) => {
          console.log("err", e);
        });
    }
  };

  const dataLoaded = !!samples;

  return (
    <TreeView
      expanded={expanded}
      multiSelect
      onItemSelectionToggle={handleChange}
      id={props?.id}
      sx={{ width: "100%" }}
    >
      <TreeItem
        nodeid={props?.id}
        itemId={props?.id}
        label={
          <Box
            sx={{
              borderBottom: "1px solid #e6e6e6",
              backgroundColor: props?.checkIfLotSelected(props?.id)
                ? "#86efac"
                : "#fff",
              cursor: "pointer",
              py: 1,
              pl: 1,
              ":hover": {
                backgroundColor: props?.checkIfLotSelected(props?.id)
                  ? "#86efac"
                  : "#b3c4ff",
              },
              width: "100%",
            }}
          >
            <Typography sx={{ fontFamily: "Open Sans" }}>
              {props?.label || props?.name}
            </Typography>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    color: "#006bb3",
                    minWidth: "max-content",
                  }}
                >
                  <span>ID:</span> {props?.ltype || props?.id}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    color: "#006bb3",
                    minWidth: "max-content",
                  }}
                >
                  No. Samples: {props?.ltype || props?.samplesNo}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    color: "#006bb3",
                    minWidth: "max-content",
                  }}
                >
                  Organization: {props?.ltype || props?.orgName}
                </Typography>
              </Box>
            </Box>
          </Box>
        }
      >
        {dataLoaded ? (
          (samples || [])?.map((node) =>
            node?.child_lots?.length > 0 ? (
              <Box
                sx={{ overflowX: "auto", width: "100%" }}
                key={node?.["Sample Id"]}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    borderBottom: "1px solid #e6e6e6",
                    cursor: "pointer",
                    px: 1,
                    ":hover": { backgroundColor: "#ccebff" },
                    backgroundColor: props?.checkIfSelected(node["Sample Id"]),
                  }}
                  onClick={() => {
                    handleSelection(
                      node["Sample Id"],
                      props?.id,
                      node?.["Sample Name"]
                    );
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      justifyContent: "space-between",
                      alignItems: "center",
                      minWidth: "max-content",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", gap: 1, minWidth: "max-content" }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                          minWidth: "max-content",
                        }}
                      >
                        {"Id: " + node?.["Sample Id"]}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                          minWidth: "max-content",
                        }}
                      >
                        {"Name: " + node?.["Sample Name"]}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                          minWidth: "max-content",
                        }}
                      >
                        {"Loc: " + node?.location}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                          minWidth: "max-content",
                        }}
                      >
                        {"Status: " + node?.status}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                          minWidth: "max-content",
                        }}
                      >
                        Shipment:
                        {node?.shipment_id === "" ||
                        node?.shipment_id === null ||
                        node?.shipment_id === undefined ? (
                          <span style={{ fontWeight: "bold" }}>None</span>
                        ) : node?.status === "ready" ? (
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            Completed
                          </span>
                        ) : (
                          <span
                            style={{ color: "#f59e0b", fontWeight: "bold" }}
                          >
                            In Proccess
                          </span>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {node?.child_lots?.map((nchli, nchk) => (
                  <WrapperTree
                    key={nchk}
                    id={nchli}
                    name={"Lot : " + nchli}
                    lot={true}
                    handleSelectSample={props?.handleSelectSample}
                    checkIfSelected={props?.checkIfSelected}
                    checkIfLotSelected={props?.checkIfLotSelected}
                    rtlot={prevlotsstr}
                  />
                ))}
              </Box>
            ) : (
              <Box
                key={node?.["Sample Id"]}
                sx={{
                  borderBottom: "1px solid #e6e6e6",
                  cursor: "pointer",
                  display: "flex",
                  pl: 1,
                  ":hover": { backgroundColor: "#ccebff" },
                  backgroundColor: props?.checkIfSelected(node["Sample Id"]),
                }}
                onClick={() => {
                  handleSelection(
                    node["Sample Id"],
                    props?.id,
                    node?.["Sample Name"]
                  );
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    height: "40px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Typography
                      sx={{ fontFamily: "Open Sans", minWidth: "max-content" }}
                    >
                      {"Id: " + node?.["Sample Id"]}
                    </Typography>
                    <Typography
                      sx={{ fontFamily: "Open Sans", minWidth: "max-content" }}
                    >
                      {"Name : " + node?.["Sample Name"]}
                    </Typography>
                    <Typography
                      sx={{ fontFamily: "Open Sans", minWidth: "max-content" }}
                    >
                      {"Loc : " + node?.Location}
                    </Typography>
                    <Typography
                      sx={{ fontFamily: "Open Sans", minWidth: "max-content" }}
                    >
                      {"Status : " + node?.status}
                    </Typography>
                    <Typography
                      sx={{ fontFamily: "Open Sans", minWidth: "max-content" }}
                    >
                      Shipment :{" "}
                      {node?.shipment_id === "" ||
                      node?.shipment_id === null ||
                      node?.shipment_id === undefined ? (
                        <span style={{ fontWeight: "bold" }}>None</span>
                      ) : node?.status === "ready" ? (
                        <span style={{ color: "green", fontWeight: "bold" }}>
                          Completed
                        </span>
                      ) : (
                        <span style={{ color: "#f59e0b", fontWeight: "bold" }}>
                          In Proccess
                        </span>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )
          )
        ) : (
          <div key="stub" />
        )}
      </TreeItem>
    </TreeView>
  );
};

export default function TreeComponent({
  childs,
  setSaved = () => {},
  getSelectedSampleIds = () => {},
  selectedSamples,
  setSelectedSamples = () => {},
  // nameDatas = [],
  // setNameDatas = () => {},
  showFiltered = false,
}) {
  const [nameDatas, setNameDatas] = useState([]);

  const checkIfLotSelected = (id) => {
    if (selectedSamples.includes("lot" + id)) return true;

    return false;
  };

  const handleSelectSample = (
    idstr,
    selectedLot,
    lotSamples,
    prevTree,
    sName
  ) => {
    if (selectedSamples.includes(idstr)) {
      let rupdated = selectedSamples.replace(idstr + ",", "");
      const anySampleInLot = (element) => rupdated.includes(element);
      const filteredOut = nameDatas?.filter((data) => data?.id !== idstr);
      if (!lotSamples.some(anySampleInLot)) {
        setNameDatas(filteredOut);
        let selectionlotupdate = rupdated
          .replace("lot" + selectedLot + ",", "")
          .replace(prevTree + "", "");
        setSelectedSamples(selectionlotupdate);
        getSelectedSampleIds(selectionlotupdate, filteredOut);
      } else {
        setNameDatas(filteredOut);
        setSelectedSamples(rupdated);
        getSelectedSampleIds(rupdated, filteredOut);
      }
    } else {
      let nupdated = selectedSamples.concat(idstr + ",");
      if (!checkIfLotSelected(selectedLot)) {
        nupdated = nupdated + "lot" + selectedLot + ",";
      }
      if (!selectedSamples.includes(prevTree)) {
        nupdated = nupdated + prevTree + "";
      }
      const updatedNames = [...nameDatas, { name: sName, id: idstr }];
      setNameDatas(updatedNames);
      setSelectedSamples(nupdated);
      getSelectedSampleIds(nupdated, updatedNames);
    }
  };

  const checkIfSelected = (id) => {
    return selectedSamples.includes(id) ? "#ccebff" : "#fff";
  };

  return (
    <Box>
      {childs?.map((chli, chk) => (
        <WrapperTree
          key={chk}
          label={chli?.name}
          ltype={chli?.type}
          id={chli?.id}
          name={"Lot : " + chli?.id}
          orgName={chli?.org_name}
          samplesNo={chli?.n_sample}
          lot={true}
          handleSelectSample={handleSelectSample}
          checkIfSelected={checkIfSelected}
          checkIfLotSelected={checkIfLotSelected}
          rtlot={""}
          showFiltered={showFiltered}
        />
      ))}
    </Box>
  );
}
