import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateSample, uploadFile } from "../services/instruments.service";
import { base64ToBlob } from "../utils/image";

import TextInputComponent from "./TextInput";
import { WebcamCapture } from "./WebcamComponent";
import FileInput from "./FileInput";
import PdfViewer from "./PdfViewer";
import axios from "axios";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { xmiURL } from "../services/url.service";
import { getSampleByID } from "../services/drxna.service";
import Tab from "./Tab";

const SampleInspection = ({
  sampleId,
  setLoading = () => {},
  showToast = () => {},
  setSamplesInfo = () => {},
}) => {
  const uid = localStorage.getItem("userid");
  const token = localStorage.getItem("usertoken");

  const [capture, setCapture] = useState("");

  const [inspectionData, setInspectionData] = useState({
    capture: "",
    description: "",
    file_list: [],
    image_list: [],
    video_list: [],
  });

  const updateValidationData = (data = inspectionData) => {
    setSamplesInfo((prevSamplesInfo) => {
      const existingSampleIndex = prevSamplesInfo.findIndex(
        (sample) => sample.sampleId === sampleId
      );

      if (existingSampleIndex !== -1) {
        const updatedSamples = [...prevSamplesInfo];
        updatedSamples[existingSampleIndex] = {
          ...prevSamplesInfo[existingSampleIndex],
          ...data,
        };
        return updatedSamples;
      }

      return [...prevSamplesInfo, { sampleId: sampleId, ...data }];
    });
  };

  const updateInspection = (
    inspectData = inspectionData,
    desc = false,
    fileT = "none"
  ) => {
    let tdata = {
      image_capture: inspectData.capture,
      image_list: inspectData.image_list,
      video_list: inspectData.video_list,
      file_list: inspectData.file_list,
      description: inspectData.description,
    };

    setLoading(true);
    updateSample({
      "Sample Id": sampleId,
      sampleDataInspect: tdata,
    })
      .then(() => {
        if (desc) {
          showToast("Description Saved!", true);
        } else if (fileT === "image") {
          showToast("Image Uploaded Successfully!", true);
        } else if (fileT === "video") {
          showToast("Video Uploaded Successfully!", true);
        } else if (fileT === "file") {
          showToast("File Uploaded Successfully!", true);
        }
        updateValidationData(tdata);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCaptureImage = (b64img) => {
    setLoading(true);
    setCapture(b64img);
    const blob = base64ToBlob(b64img, "image/jpeg");
    const file = new File([blob], "sample_capture_" + sampleId, {
      type: "image/jpeg",
    });
    uploadFile(file, uid, uid, "")
      .then((res) => {
        let pathuri = res.data?.url;

        const isnpData = {
          ...inspectionData,
          image_list: [...inspectionData?.image_list, pathuri],
        };
        setInspectionData(isnpData);
        updateInspection(isnpData, false, "image");
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUploads = (up_type, files) => {
    let flinks = [];
    let flen = files.length;
    setLoading(true);
    for (let f = 0; f < flen; f++) {
      uploadFile(files[f], uid, uid, "")
        .then((res) => {
          let pathuri = res.data?.url;
          flinks.push(pathuri);

          if (f === flen - 1) {
            setInspectionData({
              ...inspectionData,
              [up_type + ""]: [...inspectionData?.[up_type + ""], ...flinks],
            });
          }
          updateInspection(
            {
              ...inspectionData,
              [up_type + ""]: [...inspectionData?.[up_type + ""], ...flinks],
            },
            false,
            up_type === "image_list"
              ? "image"
              : up_type === "video_list"
                ? "video"
                : "file"
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const [blobData, setBlobData] = useState({ type: null, data: null });
  const [showPdf, setShowPdf] = useState(false);

  const [gettingViewData, setGettingViewData] = useState({
    status: false,
    id: "",
  });
  const viewFile = (url, id) => {
    setGettingViewData({ status: true, id: id });
    const utoken = "Bearer " + token;
    const header = {
      Authorization: utoken,
      "Content-Type": "application/json",
    };

    axios
      .get(url + "", { responseType: "blob", headers: header })
      .then((res) => {
        const contentType = res.data?.type;
        setBlobData({ type: contentType, data: res.data });
        setShowPdf(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setGettingViewData({ status: false, id: "" });
      });
  };

  // samples in package
  const [sampleDetail, getSampleDetail] = useState({});

  const getSample = () => {
    setLoading(true);
    getSampleByID(sampleId)
      .then((res) => {
        getSampleDetail(res?.data);
        const inspectionSampleData = res?.data?.sampleDataInspect;
        const dataSet = {
          capture: inspectionSampleData?.image_capture || "",
          description: inspectionSampleData?.description || "",
          file_list: inspectionSampleData?.file_list || [],
          image_list: inspectionSampleData?.image_list || [],
          video_list: inspectionSampleData?.video_list || [],
        };
        setInspectionData(dataSet);
        updateValidationData(dataSet);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSample();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tabId, setTabId] = useState("desc");
  const getTabId = (id) => {
    setTabId(id);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          height: "70px",
          zIndex: 9999,
          width: { sm: "100%", xs: "205%", position: "fixed", top: 0, left: 0 },
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Box sx={{ width: "100%" }}>
          {sampleDetail && (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
                mb: 1,
                backgroundColor: "#fff",
                p: 2,
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "20%" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                  {" "}
                  Name:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                  }}
                >
                  {sampleDetail?.["Sample Name"]}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "20%" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                  Id:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                  }}
                >
                  {sampleDetail?.["Sample Id"]}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "20%" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                  Description:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                  }}
                >
                  {sampleDetail?.Description}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "20%" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                  Location:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                  }}
                >
                  {sampleDetail?.Location || "--"}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "20%" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontFamily: "Open Sans" }}>
                  Status :
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                  }}
                >
                  {sampleDetail?.status}
                </Typography>
              </Box>
            </Box>
          )}

          <Box sx={{ mt: 4, border: "1px solid #f1f5f9", borderRadius: "8px" }}>
            <Tab
              bars={[
                { id: "desc", label: "Description" },
                { id: "images", label: "Images" },
                { id: "videos", label: "Videos" },
                { id: "files", label: "Files" },
                { id: "filler", label: "" },
              ]}
              getTabId={getTabId}
              current={tabId}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
            ></Box>
            <Box sx={{ backgroundColor: "#fff", p: 2 }}>
              {tabId === "desc" && (
                <Box>
                  <Typography
                    sx={{
                      color:
                        inspectionData?.description === "" ? "red" : "#334155",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please provide a description of this{" "}
                    <strong>sample</strong>.
                  </Typography>
                  <Box sx={{ mb: 2 }}>
                    <TextInputComponent
                      placeholder="Enter a small description"
                      rows={6}
                      value={inspectionData.description}
                      handleChange={(e) => {
                        setInspectionData({
                          ...inspectionData,
                          description: e.target.value,
                        });
                      }}
                    />
                  </Box>
                  <Button
                    variant="outlined"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "8px",
                      px: "10px",
                      textTransform: "none",
                      color: "#1e5267",
                      border: "1px solid #1e5267",
                      ":hover": { border: "1px solid #1e5267" },
                      fontFamily: "Open Sans",
                      height: "40px",
                    }}
                    onClick={() => {
                      updateInspection(inspectionData, true);
                    }}
                  >
                    Save Description
                  </Button>{" "}
                </Box>
              )}

              {tabId === "images" && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      color:
                        inspectionData?.image_list?.length > 0
                          ? "#334155"
                          : "red",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please <strong>capture/upload</strong> a picture of this
                    <strong> sample</strong>.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      pb: 5,
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: "start",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        minWidth: "max-content",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "600px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <WebcamCapture
                            imagec={capture}
                            name={"Package"}
                            handleCaptureImage={(e) => {
                              handleCaptureImage(e);
                            }}
                            instruction={true}
                          />
                        </Box>

                        <Box sx={{ mt: 2, width: "100%" }}>
                          <FileInput
                            bgColor="#f2f2f2"
                            textColor="#000"
                            uploadlabel="Upload Images"
                            acc="image/*"
                            handleChange={(e) => {
                              handleUploads("image_list", e.target.files);
                            }}
                            idn={"upload-image-sample" + sampleId}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ width: "100%" }}>
                      {inspectionData?.image_list?.length > 0 && (
                        <Grid container>
                          {inspectionData?.image_list?.map((il, ilk) => (
                            <Grid
                              item
                              xs={6}
                              sm={4}
                              lg={3}
                              key={il + "" + ilk}
                              id={il + "" + ilk}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  border: "1px solid #f1f5f9",
                                  borderRadius: "8px",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "100px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={il + "?Authorization=Bearer " + token}
                                    alt="package img"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "start",
                                    justifyContent: "space-between",
                                    px: 2,
                                    borderTop: "1px solid #f1f5f9",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      viewFile(
                                        il + "?Authorization=Bearer " + token,
                                        il + "" + ilk
                                      );
                                    }}
                                  >
                                    {gettingViewData?.status &&
                                    gettingViewData?.id === il + "" + ilk ? (
                                      <CircularProgress
                                        size={18}
                                        sx={{ color: "#98A2B3" }}
                                      />
                                    ) : (
                                      <VisibilityOutlinedIcon
                                        sx={{
                                          fontSize: "18px",
                                          color: "#98A2B3",
                                        }}
                                      />
                                    )}
                                  </IconButton>

                                  <a
                                    href={il + "?Authorization=Bearer " + token}
                                    download
                                  >
                                    <IconButton>
                                      <FileDownloadOutlinedIcon
                                        sx={{
                                          fontSize: "18px",
                                          color: "#98A2B3",
                                        }}
                                      />
                                    </IconButton>
                                  </a>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}

              {tabId === "videos" && (
                <Box sx={{ mb: 5, width: "100%", maxWidth: "500px" }}>
                  <Typography
                    sx={{
                      color: "#334155",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please <strong>upload</strong> a video of this{" "}
                    <strong> sample</strong>
                    (optional).
                  </Typography>
                  <FileInput
                    bgColor="#f2f2f2"
                    textColor="#000"
                    acc="video/*"
                    uploadlabel="Upload Videos"
                    handleChange={(e) => {
                      handleUploads("video_list", e.target.files);
                    }}
                    idn={"upload-video-sample" + sampleId}
                  />
                </Box>
              )}
              {tabId === "files" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#334155",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please <strong>upload</strong> a file for this{" "}
                    <strong> sample </strong>
                    (optional).
                  </Typography>
                  <Box>
                    {inspectionData?.file_list?.length > 0 && (
                      <Box
                        sx={{
                          my: 5,
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                      >
                        {inspectionData?.file_list?.map((ifl, iflk) => (
                          <Box
                            key={iflk}
                            id={iflk}
                            sx={{
                              mb: 1,
                              display: "flex",
                              justifyContent: "space-between",
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              p: 1,
                            }}
                          >
                            <Box
                              sx={{
                                maxWidth: "350px",
                                wordBreak: "break-all",
                              }}
                            >
                              <Typography sx={{ fontFamily: "Open Sans" }}>
                                {ifl?.replace(
                                  `${xmiURL}download/xmilab_be/`,
                                  ""
                                )}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "start",
                                ml: 2,
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  viewFile(ifl, iflk);
                                }}
                              >
                                {gettingViewData?.status &&
                                gettingViewData?.id === iflk ? (
                                  <CircularProgress
                                    size={18}
                                    sx={{ color: "#98A2B3" }}
                                  />
                                ) : (
                                  <VisibilityOutlinedIcon
                                    sx={{ fontSize: "18px", color: "#98A2B3" }}
                                  />
                                )}
                              </IconButton>

                              <a
                                href={ifl + "?Authorization=Bearer " + token}
                                download
                              >
                                <IconButton>
                                  <FileDownloadOutlinedIcon
                                    sx={{ fontSize: "18px", color: "#98A2B3" }}
                                  />
                                </IconButton>
                              </a>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}

                    <Box sx={{ width: "300px" }}>
                      <FileInput
                        bgColor="#f2f2f2"
                        textColor="#000"
                        uploadlabel="Upload Files"
                        handleChange={(e) => {
                          handleUploads("file_list", e.target.files);
                        }}
                        idn={"upload-file-sample" + sampleId}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog
        maxWidth={"md"}
        open={showPdf}
        onClose={() => {
          setShowPdf(false);
        }}
      >
        <DialogContent>
          <Box>
            {blobData?.type === "application/pdf" ? (
              <PdfViewer url={blobData.data} />
            ) : blobData.type?.startsWith("image/") ? (
              <img
                src={URL.createObjectURL(blobData.data)}
                style={{ width: "100%" }}
                alt=""
              />
            ) : (
              <Box>
                <pre>{JSON.stringify(blobData.data)}</pre>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SampleInspection;
