import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  LinearProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import HttpService from "../services/http.service";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { drxnaUrl } from "../services/url.service";
import AddIcon from "@mui/icons-material/Add";
import { searchShipment } from "../services/instruments.service";

const ShipmentPage = () => {
  const navigate = useNavigate();
  const [shipments, setShipments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const getAllShipments = (page) => {
    setLoading(true);
    HttpService.get(`${drxnaUrl}shipment/?page=${page}&limit_per_page=10`, {})
      .then((res) => {
        setShipments(res?.data?.data || []);
        setTotalPages(res?.data?.metadata?.pagination?.total_pages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllShipments(page);
  }, [page]);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState({
    status: false,
    id: "",
    name: "",
  });
  const [shipmentName, setShipmentName] = useState("");

  function generateShipmentName() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    setShipmentName(`SH-${day}-${month}-${year}`);
  }

  const creatShipment = () => {
    setLoading(true);
    HttpService.post(`${drxnaUrl}shipment/`, {
      name: shipmentName,
    })
      .then((res) => {
        setShipments((prev) => {
          return [res?.data?.data, ...prev];
        });
        setOpenCreateModal(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cancelShipment = (id) => {
    setLoading(true);
    HttpService.remove(`${drxnaUrl}shipment/${id}`)
      .then(() => {
        setShipments((prevMessages) =>
          prevMessages?.map((shipment) =>
            shipment._id === id
              ? { ...shipment, shipment_status: "cancelled" }
              : shipment
          )
        );
        setOpenWarningModal({ status: false, id: "", name: "" });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [searchQuery, setSearchQuery] = useState(null);
  const [searchPage, setSearchPage] = useState(1);
  const [totalSerchPages, setTotalSearchPages] = useState(0);

  const handleSearch = () => {
    setLoading(true);
    searchShipment(searchPage, 10, searchQuery)
      .then((res) => {
        setShipments(res?.data?.data || []);
        setTotalSearchPages(res?.data?.metadata?.pagination?.total_pages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    if (searchQuery === "") {
      setLoading(true);
      setSearchQuery(null);
      setSearchPage(1);
      HttpService.get(`${drxnaUrl}shipment/?page=${page}&limit_per_page=10`, {})
        .then((res) => {
          setShipments(res?.data?.data || []);
          setTotalPages(res?.data?.metadata?.pagination?.total_pages);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      if (searchQuery !== "" && searchQuery) {
        handleSearch();
      }
    }, 500);

    setDebounceTimeout(timeout);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery !== "" && searchQuery) {
      handleSearch(searchPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPage]);
  return (
    <Box sx={{ height: "calc(100vh - 70px - 80px)", position: "relative" }}>
      <Box sx={{ position: "fixed", top: "70px", width: "100%", zIndex: 999 }}>
        {loading && (
          <LinearProgress
            sx={{
              backgroundColor: "#f1f5f9",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#ff6412ad",
              },
            }}
          />
        )}
      </Box>
      <Box sx={{ px: { xs: 1, sm: 2, md: 5 }, py: { xs: 2, md: 4 } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            justifyContent: "space-between",
            mb: { xs: 1, sm: 2 },
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "16px", sm: "22px" },
                fontWeight: "600",
                color: "#1e293b",
                fontFamily: "Open Sans",
              }}
            >
              Shipments
            </Typography>
            <Button
              startIcon={<AddIcon />}
              sx={{
                textTransform: "none",
                background: "#1e5267",
                ":hover": { background: "#1e5267" },
                fontFamily: "Open Sans",
                borderRadius: "8px",
                height: "45px",
                px: 3,
                minWidth: "max-content",
                display: { sm: "none" },
              }}
              onClick={() => {
                generateShipmentName();
                setOpenCreateModal(true);
              }}
              variant="contained"
            >
              New Shipment
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: { xs: "wrap", sm: "nowrap" },
              alignItems: "center",
              flexDirection: { xs: "row", sm: "row" },
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                border: "1px solid #cbd5e1",
                borderRadius: "8px",
                width: "100%",
                minWidth: { xs: "300px" },

                height: "45px",
              }}
            >
              <Input
                value={searchQuery || ""}
                disableUnderline
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                variant="contained"
                placeholder="Search"
                sx={{ px: 1, width: "100%", height: "100%" }}
              />
            </Box>

            <Button
              startIcon={<AddIcon />}
              sx={{
                textTransform: "none",
                background: "#1e5267",
                ":hover": { background: "#1e5267" },
                fontFamily: "Open Sans",
                borderRadius: "8px",
                height: "45px",
                px: 3,
                minWidth: "max-content",
                display: { xs: "none", sm: "flex" },
              }}
              onClick={() => {
                generateShipmentName();
                setOpenCreateModal(true);
              }}
              variant="contained"
            >
              New Shipment
            </Button>
          </Box>
        </Box>

        {shipments?.length !== 0 && (
          <TableContainer
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              mb: 2,
            }}
          >
            <Table>
              <TableHead
                sx={{
                  borderBottom: "1px solid #e2e8f0",
                  position: "sticky",
                  top: 0,
                  left: 0,
                  background: "#fff",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      borderRight: "1px solid #e2e8f0",
                      minWidth: "max-content",
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={"600"}
                      sx={{
                        cursor: "pointer",
                        fontFamily: "Open Sans",
                        minWidth: "max-content",
                      }}
                    >
                      Shipment Name
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                    <Typography
                      variant="body2"
                      fontWeight={"600"}
                      sx={{ cursor: "pointer", fontFamily: "Open Sans" }}
                    >
                      Shipment ID
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderRight: "1px solid #e2e8f0",
                      minWidth: "max-content",
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={"600"}
                      sx={{
                        cursor: "pointer",
                        fontFamily: "Open Sans",
                        minWidth: "max-content",
                      }}
                    >
                      Shipment Status
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid #e2e8f0",
                      minWidth: "max-content",
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={"600"}
                      sx={{
                        cursor: "pointer",
                        fontFamily: "Open Sans",
                        minWidth: "max-content",
                      }}
                    >
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shipments?.map((shipment, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#fff3eb",
                      ":hover": {
                        backgroundColor: "#e0f2fe",
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/shipment/${shipment?._id}`);
                    }}
                  >
                    <TableCell
                      sx={{
                        borderRight: "1px solid #e2e8f0",
                        borderBottom: "none",
                        py: 0,
                        px: "16px",
                        minWidth: "max-content",
                      }}
                    >
                      <Typography
                        sx={{
                          minWidth: "max-content",
                          fontFamily: "Open Sans",
                        }}
                      >
                        {shipment?.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #e2e8f0",
                        borderBottom: "none",
                        px: "16px",
                      }}
                    >
                      <Box sx={{}}>
                        <Typography sx={{ fontFamily: "Open Sans" }}>
                          {shipment?._id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #e2e8f0",
                        borderBottom: "none",
                        py: 0,
                        px: "16px",
                      }}
                    >
                      <Box sx={{}}>
                        <Typography
                          sx={{
                            fontFamily: "Open Sans",
                            color:
                              shipment?.shipment_status === "cancelled"
                                ? "red"
                                : shipment?.shipment_status === "delivered"
                                  ? "#22c55e"
                                  : shipment?.shipment_status === "enroute"
                                    ? "#0ea5e9"
                                    : "#000",
                          }}
                        >
                          {shipment?.shipment_status}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #e2e8f0",
                        borderBottom: "none",
                        py: 0,
                        px: "16px",
                      }}
                    >
                      <Box sx={{}}>
                        {shipment?.shipment_status !== "cancelled" &&
                          shipment?.shipment_status !== "delivered" && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenWarningModal({
                                  status: true,
                                  id: shipment?._id,
                                  name: shipment?.name,
                                });
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {totalPages > 1 && !searchQuery && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={(e, p) => {
              setPage(p);
            }}
            variant="outlined"
            shape="rounded"
          />
        )}
        {totalSerchPages > 1 && searchQuery && (
          <Pagination
            count={totalSerchPages}
            page={searchPage}
            onChange={(e, p) => {
              setSearchPage(p);
            }}
            variant="outlined"
            shape="rounded"
          />
        )}

        <Dialog
          fullWidth={true}
          sx={{ height: 450 }}
          maxWidth={"xs"}
          open={openCreateModal}
          onClose={() => {
            setOpenCreateModal(false);
          }}
        >
          <DialogTitle>
            <Typography sx={{ fontFamily: "Open Sans" }}>
              Add Package Name
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Input
                value={shipmentName}
                sx={{ width: "100%" }}
                onChange={(e) => {
                  setShipmentName(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button
                onClick={creatShipment}
                sx={{
                  width: "100%",
                  textTransform: "none",
                  background: "#1e5267",
                  ":hover": { background: "#1e5267" },
                  fontFamily: "Open Sans",
                  borderRadius: "8px",
                }}
                variant="contained"
              >
                Create
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          sx={{ height: 450 }}
          maxWidth={"xs"}
          open={openWarningModal?.status}
          onClose={() => {
            setOpenWarningModal({ status: false, id: "", name: "" });
          }}
        >
          <DialogTitle>
            <Typography
              sx={{
                fontFamily: "Open Sans",
                // color: "warning",
                fontSize: "20px",
                // fontWeight: "600",
                textAlign: "center",
              }}
            >
              Are you sure you want to cancel the shipment "
              <strong>{openWarningModal?.name}</strong>"?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontFamily: "Open Sans", textAlign: "center" }}>
              Click '<strong>Continue</strong>' to proceed with the cancellation
              or '<strong>Abort</strong>' to keep the shipment active.
            </Typography>
            <Box sx={{ display: "flex", gap: 3, mt: 3 }}>
              <Button
                onClick={() => {
                  setOpenWarningModal({ status: false, id: "", name: "" });
                }}
                variant="outlined"
                color="success"
                sx={{
                  background: "#fff",
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                }}
              >
                Abort
              </Button>
              <Button
                onClick={() => {
                  cancelShipment(openWarningModal?.id);
                }}
                color="warning"
                sx={{
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                }}
                variant="contained"
              >
                Continue
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default ShipmentPage;
