export const xmiURL = process.env.REACT_APP_XMI_URL;

export const loginURL = xmiURL + "login";

export const uploadURL = xmiURL + "upload";

export const getSamplesURL = xmiURL + "get_samples";

export const getASampleURL = xmiURL + "get_sample";

export const userURL = xmiURL + "get_user";

export const updateSampleURL = xmiURL + "update_sample";

export const drxnaUrl = process.env.REACT_APP_DRXNA_URL;

export const shipmentURL = drxnaUrl + "shipment";

export const contentURL = process.env.REACT_APP_CONTENT_URL;

export const uploadSampleSheetURL = contentURL + "user/media";

export const topicURL = contentURL + "topic";

export const courseContentURL = contentURL + "content";

export const userContentURL = contentURL + "user";

export const userMediaBaseURL = contentURL + "user/media";
